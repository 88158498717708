import {iPlatform} from '@root/settings/interfaces';

export const PlatformType = {
  'master.up.teamcraft.ru': (item) => {
    return {
      role: ''
    }
  },
  'bitkogan.up.teamcraft.ru': (item) => {
    return {
      role: ''
    }
  },
  'dev.up.teamcraft.ru': (item): iPlatform => {
    return {
      role: ''
    }
  },
  localhost: (item): iPlatform => {
    return {
      role: ''
    }
  },
  default: (item): iPlatform => {
    return {
      role: ''
    }
  }
};
