import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Platform } from '@root/platforms/platform.model';
import { PlatformType } from '@root/platforms/platform.type';
import { PrivateDataService } from '@root/private-data/private-data.service';
import { BitKoganService } from '@root/platforms/bitkogan/bitkogan.service';
import { DOCUMENT } from '@angular/common';
import { iPlatform } from '@root/settings/interfaces';
import { StorageService } from '@root/shared/modules/storage/services/storage.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private _role: string;
  private _role$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _platform: Platform;
  private _platform$: ReplaySubject<iPlatform> = new ReplaySubject<iPlatform>();
  private domain: string;

  constructor(
    public privateDataService: PrivateDataService,
    public bitKoganService: BitKoganService,
    public storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.parseDomain();
  }

  getRole() {
    this.privateDataService.getLastRecord();
    this.privateDataService.lastRecord$
      .pipe(
        map((item) => {
          let role = this.storageService.getRole();
          return role ? null : item;
        }))
      .subscribe((item) => {
        if (item) {
          let roleField = this.bitKoganService.getFieldRole();

          if (!item[roleField]) {
            return;
          }

          let value = item[roleField].value;

          let data = {
            ...this.platform,
            role: value || this.bitKoganService.getBehaviorByPrivateData(item)
          };

          this.storageService.setRole(data.role);
          this.privateDataService.lastRecordAsSubject.complete();
          this.platform = data;
        }
      });
  }

  getProfileRole(sum: number): string {
    let value = '';

    value = this.bitKoganService.getBehaviorByPrivateDataSum(sum);
    return value;
  }

  private parseDomain() {
    this.domain = location.hostname;
    console.log('domain', this.domain);
    if (PlatformType[this.domain]) {
      this.platform = PlatformType[this.domain]({});
    } else {
      this.platform = PlatformType.default({});
    }
  }

  get platform$(): Observable<iPlatform> {
    return this._platform$;
  }

  get platform() {
    return this._platform;
  }

  set platform(value: any) {
    this._platform = value;
    this._platform$.next(value);
  }

  get role$(): Observable<string> {
    return this._role$.asObservable();
  }

  get role(): string {
    return this._role;
  }

  set role(value: string) {
    this._role = value;
  }
}
